<template>
    <div id="read" :class="{ 'is-loading': loading }">
        <div v-if="loading" class="is-ghost">
            <i class="is-ghost fa-solid fa-compact-disc fa-flip"></i>
            LOADING EPISODE {{ newEpisode.number }}
        </div>
        <div v-else class="comic-wraper">
            <comic-player ref="ComicPlayer" @stop="playerPause" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ComicPlayer from "@/components/ComicPlayer.vue";

// TODO: Options modal (pause player)

// Arrow Left/Right : Previous/Next comic
// Arrow Left/Right + Shift, Page Up/Down: Previous/Next episode
// ESC, P, up/down: toggle Pause options
// M: toggle reading mode
// +/-: reading speed faster/slower

// Swipe Left: Previous comic
// Swipe Right: Next comic
// Swipe Left (two fingers): First comic
// Swipe Right (two fingers): Last comic

export default {
    name: "Read",
    components: { ComicPlayer },
    data() {
        return {
            loading: true,
            oldEpisodeNum: null,
            mouseTimer: null,
            cursorVisible: true,
        };
    },
    created() {
        // window.addEventListener("mousemove", this.onMouseMove);
    },
    beforeUnmount() {
        this.setPaused(false);
        // window.removeEventListener("mousemove", this.onMouseMove);
        // document.body.style.cursor = "default";
        // this.cursorVisible = true;
    },
    mounted() {
        window.scrollTo(0, 0);
        // document.body.classList.remove("scanlines");
        this.loadEpisode();
        this.setEditMode(false);
        setTimeout(() => {
            this.setPaused(false);
        }, 100);
    },
    computed: {
        ...mapGetters(["episode", "paused"]),
        routeName() {
            return this.$route.name;
        },
        newEpisode() {
            if (this.routeName !== "Read") return;
            const q = this.$route.query.episode.split(".");
            if (q.length === 0) return { number: 0, panel: 0 };
            if (q.length === 1) return { number: q[0], panel: 0 };
            return { number: q[0], panel: q[1] };
        },
    },
    methods: {
        ...mapMutations(["setEditMode", "setPaused"]),
        loadEpisode() {
            console.log("loading episode", this.newEpisode.number + "." + this.newEpisode.panel);

            if (this.newEpisode.number !== this.oldEpisodeNum) {
                this.setPaused(true);
                this.oldEpisodeNum = this.newEpisode.number;
                if (!this.newEpisode.panel) this.newEpisode.panel = 0;
                this.$store
                    .dispatch("loadEpisode", {
                        episode: this.newEpisode.number,
                        panel: this.newEpisode.panel,
                    })
                    .then((loaded) => {
                        if (loaded) {
                            this.loading = false;
                            // setTimeout(() => {
                            // this.setPaused(false);
                            // }, 100);
                        } else {
                            // ERROR
                            console.error(">>>>>> NOT LOADED");
                            this.$router.push({
                                name: "NotFound",
                            });
                        }
                    });
            }
        },
        onMouseMove() {
            if (this.mouseTimer) {
                window.clearTimeout(this.mouseTimer);
            }
            if (!this.cursorVisible) {
                document.body.style.cursor = "default";
                this.cursorVisible = true;
            }
            this.mouseTimer = setTimeout(() => {
                this.disappearCursor();
            }, 3000);
        },
        disappearCursor() {
            if (!this.paused) {
                this.mouseTimer = null;
                document.body.style.cursor = "none";
                this.cursorVisible = false;
            }
        },
        playerPause() {
            console.log("----------------- playerPause");
        },
    },
};
</script>

<style>
/* html {
    overflow: hidden;
}
body {
    background-color: black;
} */
</style>

